<template>
 <div style='width: 100%; height: 100%; position: relative;'>
   
     <div class='transition' :style='getAppStyle()+getControlStyle()'>
    	<!-- CONTROLS -->
    	    
		    <div style='float: top; width: 100%; vertical-align: middle;'>
		        <!--
		        <div style='vertical-align: middle; text-align: center; font-size: 14pt; font-weight: bold;width: 100%;'>SEARCH 1.0</div>
		        <div style='vertical-align: middle; text-align: center; font-size: 11pt; font-weight: bold;width: 100%;'>CONTROLS</div>
		        <br/>
		        <br/>
		        -->
			    <span class='SVcontrolMain'>Search:</span><br/>
			    <div class="dontWrap" :style='getAppStyle()'>
			    <input ref="entersearch" v-model="searchTerm" 
			                @keypress.enter="enterPressed()" 
    						@focus="$event.target.select()"
    						placeholder='enter search...'
    						:style="getAppStyle()+'width: 100%; height: 24pt;'"/> 
    			<button class='button' @click="enterPressed" :style="getAppStyle()">Search</button>
    			</div>
			   		    	<br/>
			   		    	<br/>
			   		    	<br/>
			    <span class='SVcontrolLabel'>Search data: </span>
		    	&nbsp;
		    	<app-switch v-model="withData" :checked="withData"/>
		    	<br/>
		    	<span class='SVcontrolLabel'>Search modules: </span>
		    	&nbsp;
		    	<app-switch v-model="withModules" :checked="withModules"/>
		    	<br/>
		    	<hr/>
		    	<span class='SVcontrolLabel'>Show result as text: </span>
		    	&nbsp;
		    	<app-switch v-model="withText" :checked="withText"/>
		    	<br/>
		    	<span class='SVcontrolLabel'>Show result as tree: </span>
		    	&nbsp;
		    	<app-switch v-model="withTree" :checked="withTree"/>
		    	<br/>
		    	<br/>
		    	<hr/>
		    	<span class='SVcontrolLabel'>Show Controls: </span>
		    	&nbsp;
		    	<app-switch v-model="controlsRight" :checked="controlsRight"/>
		    	<br/>
		    	
		    	
		    </div> <!-- CONTROLS -->
    </div>
    
    <!-- MAIN -->
	<div class='transition' style='float: left; width: 77%;'> 
	    <!-- HEADER -->
	    <div style='float: top; width: 100%; height: 28pt; overflow-y: scroll;'>
		{{info}}
	  	</div>
	    <!-- HEADER -->
	    
	    <!-- TABLE -->
	    <div style='width: 100%; height: calc(100vh - 120px); overflow-y: scroll;'>
	    
			<div v-for="(menu,idx) in data" :key="'M'+idx">
			   <div v-show="withText">
			       
		    	   <A :title="'Open '+menu.title" @click="open(menu)" style="font-weight: bold;">{{ menu.title}}</A> ({{menu.menu}})

		    	   <div  v-for="found in menu.columns" :key="found" style="font-weight: bold; padding-left: 5pt;">
		    	   		{{found}}
		    	   		<div v-for="entry in menu.entities[found]" :key="entry" style="font-weight: normal; padding-left: 5pt;">
		    	   		<a :title="'Open record '+entry.visualDisplay+' in '+menu.title" @click='nodeToOpen(menu.metadata, entry)'>{{entry.id}} {{entry.visualDisplay}}</a> <span style='font-size: 10pt;'>({{entry[found]}})</span>
		
		    	   		</div>
		    	   </div>

	    	   </div>

	    	   <div v-show="withTree && menu.ids && menu.ids.length > 0">
	    	  
	    	   <GFWTreeView :height="0"
	    	                :noDate="true" 
	    	                :openModule="menu.entity" 
	    	                :ids=menu.ids 
	    	                @nodeClicked="nodeClicked"
	    	                :modules=[menu.entity]>
	    	  </GFWTreeView>
	    	  </div>
	    	</div>

    	</div>  <!-- TABLE -->
    </div>  <!-- MAIN -->
    <ProgressBar v-if="showProgressBar" :generalInfo=pbTitle :action=pbAction @action=action></ProgressBar>
    <GFWEditorRouter v-show='showEditor' @update="update" @insert="insert" @close="closeEditor" 
    :selectedIndex="selectedIndex" 
    :dataArray="allEntities" 
    :entityMap="entityMap"
    :metadata="metadata" 
    :dataId="dataId"
    :mapping="mapping" 
    :time="new Date().getTime()" 
    :dataRecord="selectedRecord">
    </GFWEditorRouter>
 </div>
</template>

<script>
import {HTTP, fwAPI, searchAPI, invAPI, reportAPI, setReload, myLocale, showError} from '@/variables.js';
import { getAppStyle, getAppStyleFG, setDarkMode, initAppMode, hexToRgb, isDark, getBG, getBGColor } from '@/AppStyle.js';

import InputTime from '@/components/inputElements/InputTime2';
import Switch from '@/components/Switch';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import InputTimezone from '@/components/inputElements/InputTimezone';
import GridBreakView from '@/components/GridBreakView';
import InputDatePick from '@/components/inputElements/InputDatePick'; 
import GFWTreeView from '@/components/GFWTreeView';
import ProgressBar from '@/components/ProgressBar';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import { D3LineChart } from 'vue-d3-charts';
var timers = [];

export default {
  name: 'GFW Schedule View',
  components : {
   'app-switch': Switch, GFWTreeView, ProgressBar
  },
  data () {
    return {
      stationId: 0,
      station: {},
      chartType: 'bar',
      chartData: [],
      chart_config: "",
      selectedStation: {},
      currency: {},
      stations: [],
      data: [],
      info: "",
      fromDate: {},
      untilDate: {},
      search: "",
      searchTerm: "",
      loadingActive: false,
      controlsRight: false,
      withText: true,
      withTree: false,
      withData: true,
      withModules: true,
      
      showProgressBar: false,
      pbAction: "",
      pbTitle: "",
      
      showEditor: false,
      entityMap: {},
      mapping: [],
      metadata: {},
      dataId: 0,
      selectedRecord: {},
      selectedIndex: 0,
      allEntities: [],
      
      getAppStyle
    }
  },    
  methods: {
    myLocale() { return myLocale();},
    getControlStyle() {
    	if ( this.controlsRight )
    	{
    		return "float: right; width: 22%; height: calc(100vh - 120px); padding: 2ex;"
    	}
    	return "float: left; width: 22%; height: calc(100vh - 120px); padding: 2ex;"
    },
    open(menu)
    {
    	if ( menu.entity.startsWith('/'))
    	{
    		this.$router.replace(menu.entity);
    	}
    	else
    	{
    		let item = "/fw/"+menu.entity;
    		this.$router.replace(item);
    	}
    },
    nodeClicked( module, data, metadata)
    {
    	this.nodeToOpen( metadata, data)
    	
    },
    action( what, myId, api)
    {
   		this[what](myId, api)
    },
    nodeToOpen( metadata, node)
    {
        this.mapping = [ {row: 0, dataId: node.id, rId: 0, visible: true}];
    	this.allEntities = [ node];
    	this.selectedRecord = {};
        Object.assign( this.selectedRecord, node);
		this.dataId = node.id;
    	this.metadata = metadata;
    	//alert(metadata.name)
    	let that = this;
    	let time = new Date().getTime();
    	let mapperLoad = new Promise(function (resolve, reject) {
	    
		
		console.log(fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+metadata.name+"/allEntitiesWithDate/2022-02-02/true/1");
		console.log( JSON.stringify([node.id]));
    	HTTP.post( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+metadata.name+"/allEntitiesWithDate/null/true/1", [node.id])
        		 .then( response => 
                 {
                 	let dur = (new Date().getTime()-time);
               		console.log("LOADED rights in " + dur +"ms");  
                    that.allEntities = response.data.data;
                    //Object.assign( );
                    that.selectedRecord = that.allEntities[0]
                    that.metadata = response.data.metadata;
                    that.entityMap = response.data.entity2DataMap;
                    if ( that.selectedRecord.extraData )
                    {
                    	for ( let i in that.selectedRecord.extraData )
                    	{
                    		that.selectedRecord[i] = that.selectedRecord.extraData[i];
                    		//alert("<"+i+">" + "->" + that.selectedRecord[i])
                    	}
                    }
                    that.showEditor = true;
                          
                 }).catch(e => {
                        //console.log( JSON.stringify(e));
                        that.$toast.error("loading data for user: " + e.response.data, 'Error', { position: "topRight" });

                    });   
		   
	    	});
    	
    	//this.$emit(node.module.toLowerCase(), node.entitydata);
    },
    closeEditor()
    {
         this.showEditor = false;
    },
    focusInput() {
      this.$refs.entersearch.focus();
    },
    enterPressed() {
    	
    	this.search = this.searchTerm;
    	this.getData();
    },
    
    getData() {
    	this.pbAction = "getDataInternal";
        this.pbTitle = "Searching";
 	    this.showProgressBar = true;
    },
    getDataInternal(myId, api) {
        let that = this;
        this.data = new Array();
		this.startLoader();
        
		
 	    
        let time = new Date().getTime();
        this.startLoader();
        that.info = "searching...";
    	let dataLoad = new Promise(function (resolve, reject) {
            console.log( searchAPI+"/search/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.search);
            
       		HTTP.get( searchAPI+"/search/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.unitId+"/"+sessionStorage.userId+"/"+that.withData+"/"+that.withModules+"/"+that.search+"/"+myId)

        		 .then( response => 
                 {
                 	let dur = (new Date().getTime()-time);
               		console.log("LOADED DATA in " + dur +"ms");  
                    that.data = response.data;
                    that.info = that.data.length +" entry(s)/record(s) found";
                    that.showProgressBar = false;
                    
                    resolve (that.data);                    
                 }).catch(e => {
                        showError( that.$toast, "search failed ", e)
                        that.showProgressBar = false;
                        that.stopLoader();
                        
                        that.info = "";
                        reject ();
                    });
	      });
    },
    startLoader()
    {
    	if ( !this.loadingActive)
      	{
	      	this.loadingActive = true;
	      	this.loader = this.$loading.show({
	                    // Optional parameters
	                    container: this.$refs.formContainer,
	                    canCancel: true,
	                    programmatic: false,
	                    onCancel: this.onCancel,
	                    color: '#000000',
					    loader: 'dots',
					    width: 64,
					    height: 64,
					    active: true,
					    backgroundColor: '#ffffff',
					    opacity: 0.5,
					    zIndex: 999,
	                });
	    }
    },
    stopLoader()
	{
		clearInterval( timers.pop());
		this.loadingActive = false;
		this.loader.hide();
	}
  },
  created() {
   
  	this.fromDate = new Date();
  	this.untilDate = new Date();

  },
  mounted() {
  	this.focusInput();
  },

  watch: {
  /*
  	fromDate: function() { this.getData() },
  	untilDate: function() { this.getData() },
  	selectedStation: function() { this.getData() }
  	*/
  },
  updated() {
  		//console.log("update...");
	  this.$nextTick(function () {
	        // Code that will run only after the
		    // entire view has been re-rendered
		    //console.log("stopping loader");
		    if (this.loadingActive)
		    {
		        timers.push( setTimeout( this.stopLoader, 100));
		    }
		  })
 	}
}
</script>
<style>

 .transition {
 	transition: all 1s;
   -webkit-transition: all 1s;
 }
  
.transitionFast {
 	transition: all .4s;
   -webkit-transition: all .4s;
 }
.dontWrap {
  white-space: nowrap; /* Don't forget this one */
  text-overflow: ellipsis; 
  width: 100%;
  display: flex;
}
.header { 
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 7pt; 
  font-weight: bold; 
  width: 100% !important; 
  height: 28pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc;
  background-color: #eef; 
  }
.headerDay { 
  font-size: 12pt; 
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%; 
  height: 28pt; 
  border-left: 1pt solid #ccc; 
  border-bottom: 1pt solid #ccc; 
  background-color: #eef; 
 }
.adMain { 
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute; 
  z-Index: 17; 
  background-color: #fff; 
  margin-left: 4pt; 
  margin-right: 4pt; 
  color: #666; 
  width: 95%; 

    opacity:1;
    transition: all .5s;
   -webkit-transition: all .5s;
}

.gridMain { position: absolute; z-Index: 2; text-align: center; width: 99% ;border: 1pt solid #aaa; opacity:1;
    transition: all 1s;
   -webkit-transition: all 1s; }
   
.SVcontrolLabel {
	font-weight: normal;
	padding-top: 9pt;
}
.SVcontrolMain {
	font-weight: bold;
	padding-top: 9pt;
}
</style>
