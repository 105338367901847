<template>
 <div :style='getAppStyle()'>
    <div class="tree3 transition" :style="getAppStyle()+getMainStyle()">
      <span v-for="(mdl,idx) in modules" :key="'mdl'+idx">
      	<button v-if="entity(mdl)==selctedModule" class="button selected tree-mdl-btn" type="button" @click="treeswitch(mdl)">{{name(mdl)}}</button>
      	<button v-else class="button tree-mdl-btn" type="button" @click="treeswitch(mdl)">{{name(mdl)}}</button>
	  </span>
      <div v-show="! noDate" style='border-bottom: 4pt outset #black;'></div>
      <span v-show="! noDate" style='display: inline-flex; width: 35%;'> 
      <InputDatePick :auto="false" v-model="refDate" @change="load"/>
      </span>
      <span style='display: inline-flex; width: 4%;'></span>
      <span style='display: inline-flex; width: 56%;'>
      <input class="tree-search-input" style='height: 28px; margin-right: 5px;' type="text" v-model="searchword" @keyup.enter="enter" @focus="$event.target.select()" placeholder="enter search..."/>
     
      <button style='width: 100px; margin-right: 5px;' class="button tree-search-btn" type="button" @click="search">search</button>
      </span>
      <div :style="getAppStyle()">
      <v-tree ref='tree1' style='z-index: 0 !important;' :tpl="tpl" :radio="true" :canDeleteRoot="true" :data='treeData1' :multiple='false'  @node-check='nodechecked' @node-click='nodeClicked' @async-load-nodes='asyncLoad' />
      </div>
    </div>
    
    <ContextMenu ref="menu">
      <template v-if="contextData && contextData.entitydata"  slot-scope="{ contextData }">
        <ContextMenuItem  @clicked="nodeToOpen(event, contextData)">
          Open {{contextData.module}}: {{ contextData.entitydata.visualDisplay }} 
        </ContextMenuItem>
       
       </template>
    </ContextMenu>
     
    <ErrorPopUp :errorType="errorTypePU" :error="errorPU"></ErrorPopUp>
    <GFWEditorRouter v-show='showEditor' @close="closeEditor" 
    :selectedIndex="selectedIndex" 
    :dataArray="allEntities" 
    :entityMap="entityMap"
    :metadata="metadata" 
    :dataId="dataId"
    :mapping="mapping" 
    :time="new Date().getTime()" 
    :dataRecord="selectedRecord">
    </GFWEditorRouter>
 </div>
</template>

<script>
import ErrorPopUp from '@/components/ErrorPopUp';
import { getAppStyle, setDarkMode, initAppMode } from '@/AppStyle.js';
import {HTTP, fwAPI, setReload} from '@/variables.js';
import { tr } from '@/translate.js';
import ContextMenu from '@/components/ContextMenu';
import ContextMenuItem from '@/components/ContextMenuItem';
import InputDatePick from '@/components/inputElements/InputDatePick3';
export default {
  name: 'GFW TreeView',
  components : {
    ErrorPopUp, ContextMenu, ContextMenuItem, InputDatePick
  },
  props: {
    openModule: { type: String, default: "XXX" },
    noDate: { type: Boolean, default: false },
    date: { type: String, default: '' },
    ids: { type: Array },
    modules: { type: Array },
    height: { type: Number, default: 50 }
  },
  data () {
    return {
      searchword: '',
      refDate: '',
      initSelected: ['node-1'],
      treeData1: [{
        title: 'yy',
        module: 'yyy',
        expanded: false,
        async: true
      }],
      selctedModule: "",
      showEditor: false,
      entityMap: {},
      mapping: [],
      metadata: {},
      dataId: 0,
      selectedRecord: {},
      selectedIndex: 0,
      allEntities: [],
      errorPU: {},
      errorTypePU: "",
      getAppStyle
    }
  },
  methods: {
    nodechecked (node, v) {
      alert('that a node-check envent ...' + node.title + v)
    },
    name( mdl )
    {
    	return tr( mdl)
    },
    entity( mdl )
    {
    	if ( mdl.name )
    	{
    		return mdl.name;
    	}
    	return mdl;
    },
    treeswitch( mdl )
    {
        let mdlObj = this.modules.find( p => this.entity(p) === this.entity(mdl) );
        //alert( JSON.stringify( mdl)+"/"+ + JSON.stringify( this.modules)+ "/"+ JSON.stringify( mdlObj));
    	let name = this.entity( mdlObj);
    	let title = this.name( mdlObj);

    	this.treeData1 = [ {title: title,
        module: name,
        expanded: true,
        async: true }];
        this.asyncLoad (this.treeData1[0]);
        this.selctedModule = name;
    },
    load(date)
    {
        let mdl = this.modules.find( p => this.entity(p) === this.selctedModule );
    	let title = this.name( mdl);
        this.refDate = date;
    	this.treeData1 = [ {title: title,
        module: this.selctedModule,
        expanded: true,
        async: true }];
        this.asyncLoad (this.treeData1[0]);
    },
    getMainStyle()
    {
    	
    	if ( this.height <= 0 )
    	{
    		return "";
    	}
    	let mh = window.innerHeight;
    	return "height: " + ((mh*this.height)*.01)+"px;"
    },
    nodeClicked( event, node, v)
    {
        this.$emit(node.module, node.entitydata, node.metadata);
        
        this.$emit("nodeClicked", node.module, node.entitydata, node.metadata);
    },
    nodeToOpen( event, node, v)
    {
        let that = this;
        this.$refs.menu.close();
        this.mapping = [ {row: 0, dataId: node.id, rId: 0, visible: true}];
    	this.allEntities = [ node.entitydata];
    	this.selectedRecord = {};
        Object.assign( this.selectedRecord, node.entitydata);
		this.dataId = node.id;
    	this.metadata = node.metadata;
    	that.showEditor = true;
    	
		   
    	//this.$emit(node.module.toLowerCase(), node.entitydata);
    },
    nodeRClicked(event, node, v)
    {
    	event.preventDefault();
		this.selectedRecord = {};
		this.metadata = node.metadata;
        Object.assign( this.selectedRecord, node.entitydata);
        this.$refs.menu.open(event, node )
    },
    closeEditor()
    {
         this.showEditor = false;
    },
    // tpl (node, ctx, parent, index, props) {
    tpl (...args) {
      let {0: node, 1: ctx, 2: parent, 3: index} = args
      
      let titleClass = node.selected ? 'node-title node-selected' : 'node-title'
      let that = this;
      if (node.searched) titleClass += ' node-searched' ;
      titleClass += " "+getAppStyle();
      let appStyle=getAppStyle();
      return <span style={appStyle}>
       
         <span class={titleClass} domPropsInnerHTML={node.title} onContextmenu={() => {
           that.nodeRClicked( event, node );}} 
           onClick={() => {
           that.nodeClicked( event, node );}}
         ></span>
        <button class="treebtn3" style={appStyle} onClick={() => this.$refs.tree1.delNode(node, parent, index)}>X</button>
      
      </span>
    },

    async asyncLoad (node) {
      //alert("asyncLoad> " + JSON.stringify(node)); 
      const {checked = false} = node
      this.$set(node, 'loading', true)
      //let pro = [{title: 'bbbllub', async: true}, {title: 'xxxft', async: true}, {title: 'gem'}];
      let pro = null;
      var that = this;
      var openAfter = [];
     
      if ( ! node.links && ! node.link  )
      {
	        pro = await new Promise(function (resolve, reject) {
		        let date = new Date(that.refDate).toISOString().split('T')[0];
		        if ( ! that.ids || that.ids.length == 0 )
		        {
		        	that.ids = new Array();
		        }
		        HTTP.post( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+node.module+"/allEntitiesWithDate/"+date+"/true/200", that.ids)
		            .then( response => 
		            { 
		                let respData = response.data;
		               
		                that.entityMap = response.data.entity2DataMap;
		                var dataArr = new Array();
		                for ( var data in respData.data )
		                {
		                	let newNode = {title: respData.data[data].name, id: respData.data[data].id, entitydata: respData.data[data], metadata: respData.metadata, links: respData.metadata.links, module: respData.metadata.name, async: true};
		                	dataArr.push( newNode);
		                	//openAfter.push( newNode);
		                }
		                
		                resolve (dataArr);
		            }).catch(e => {
		                    this.error("loading data for client", e);
		                    reject ();
		            });
		      })
	  }
	  else if ( node.link  )
	  {
	  pro = await new Promise(function (resolve, reject) {
	   //console.log( fwAPI+"/"+sessionStorage.tenantId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+node.module+"/link/"+node.id);
	   //console.log( JSON.stringify(node.link) );
	   //console.log( JSON.stringify(node.entitydata) );
	        HTTP.post( fwAPI+"/getLink/"+sessionStorage.tenantId+"/"+sessionStorage.accessPointId+"/"+sessionStorage.userId+"/"+sessionStorage.unitId+"/"+node.module+"/"+node.id, 
    	           { link: node.link, data:  JSON.stringify(node.entitydata) })
	            .then( response => 
	            { 
	                let respData = response.data;
	                
	                var dataArr = new Array();
	                for ( var data in respData.data )
	                {
	                	dataArr.push( {title: respData.data[data].name, id: respData.data[data].id, entitydata: respData.data[data], metadata: respData.metadata, links: respData.metadata.links, module: node.link.name, async: true} );
	                }
	                
	                resolve (dataArr);
	            }).catch(e => {
	                    this.error("loading data for client", e);
	                    reject ();
	            });
	      })
	  
	  }
	  else
	  {
	  		 var dataArr = new Array();
	  		for ( var data in node.links )
	                {
	                	let newNode = {title: node.links[data].name, id: node.id, entitydata: node.entitydata, link: node.links[data], module: node.module, expanded: true, async: true};
	                	dataArr.push( newNode );
	                	openAfter.push( newNode)
	                }
	         pro = dataArr;
	  }
      if (!node.children) {
        this.$set(node, 'children', [])
      }
      node.children.push(...pro)
      if ( openAfter && openAfter.length > 0 )
      {
      	for ( var link in openAfter )
      	{
      		//alert( JSON.stringify(openAfter[link]));
      		this.asyncLoad( openAfter[link]);
      	}
      }
      this.$set(node, 'loading', false)
      if (checked) {
        this.$refs.tree2.childCheckedHandle(node, checked)
      }
    },
    enter() {
    	this.search();
    },
    search () {
      this.$refs.tree1.searchNodes(this.searchword)
    },
    error(txt, err)
      {	
      	this.errorTypePU=txt;
      	this.errorPU=err;
      }
  },
  created() {
    this.refDate = new Date();
    if ( this.date)
    {
    	this.refDate = new Date(this.date);
    }
    else
    {
    	this.refDate.setDate( this.refDate.getDate() + 7) ;
    }
    //alert( 'created -> ' + this.openModule);
  	this.treeswitch( this.openModule );
  	initAppMode();
  },
  watch: { 
		date: function() { this.$set( this, "refDate", new Date(this.date)); }
  }
}
</script>
<style>
.transition {
 	transition: all .2s;
   -webkit-transition: all .2s;
 }
.tree3{
  float: left;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow-y: scroll;
  background-color: white;
}
.tree-search-input{
  width: 70%;
  padding: 4px 4px;
  outline: none;
  border-radius: 2px;
  border:1px solid #ccc;
}
.node-title {
  font-size: 10pt;
  line-height: 0.5em;
  height: .5em;
  
}
.halo-tree {
	z-index: 0 !important;
}
.tree-node-el {
	z-index: 0 !important;
} 
.halo-tree ul {
    padding-left: 17px;
    background-color: transparent;
    /* padding-top: 10px; */
}
.leaf {
/*padding: 2px 2px 3px 3px !important;*/
}
.treebtn3{
  background-color: transparent;
  border: 1px solid #ccc;
  padding: 3px 5px;
  border-radius: 5px;
  margin-left: 5px;
  color: rgb(63, 63, 63);
  display: none;
}
.tree-search-btn{
  width: 25%;
  padding: 6px 8px;
  outline: none;
  border-radius: 6px;
  height: 22pt;
  bac_kground-color: rgb(218, 218, 218);
  border:1px solid rgb(226, 225, 225);
  color: rgb(117, 117, 117);
}
.tree-mdl-btn{
  width: 33%;
  padding: 6px 8px;
  outline: none;
  border-radius: 6px;
  height: 18pt;
  font-size: 9pt;
  background-color: rgb(218, 218, 218);
  border:1px solid rgb(226, 225, 225);
  color: rgb(0, 0, 0);
  margin-bottom: 4pt;
}
.selected {
  background-color: rgb(88, 218, 218);
  border:1px solid rgb(226, 225, 225);
}
</style>
